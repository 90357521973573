<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Employee Master
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Employee Master</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6">
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                    <!-- data-bs-toggle="modal"
                    data-bs-target="#modals-slide-in" -->
                    <div class="float-end col-6">
                      <button
                        type="button"
                        class="btn btn-gradient-primary float-end"
                        @click="add"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                <td>{{ index + paginationData.from }}</td>
                                <td><img class="avatar " :src="li.profile_url" alt="Profile" height="55" width="55" /></td>
                                <td>{{ li.dep_name }}</td>
                                <td>{{ li.des_name }}</td>
                                <td>{{ li.first_name }}</td>
                                <!-- <td>{{ li.middle_name }}</td> -->
                                <td>{{ li.last_name }}</td>
                                <td>{{ li.email }}</td>
                                <td>{{ li.mobile }}</td>
                                 <!-- <td>{{ li.wallet_balance }}</td> -->
                                <!-- <td>{{ li.created_at }}</td> -->
                                <td>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="edit(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="edit" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteDes(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="trash-2" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="12" class="text-center">No Data Found</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <teleport to="#modal">
    <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
      <div class="modal-dialog">
        <form
          class="add-new-user modal-content pt-0"
          @submit.prevent="submitForm"
          @keydown="form.onKeydown($event)"
          enctype="multipart/form-data"
        >
          <input type="hidden" name="id" :v-modal="form.id" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="[isShow='', isDisplay='none']"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ modalName }} User
            </h4>
          </div>
          <div class="modal-body flex-grow-1">

            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">First Name</label>
              <input
                type="text" class="form-control" name="first_name" v-model="form.first_name" placeholder="First Name" @keyup="
                  form.first_name.length > 0
                    ? [(firstError = 'none'), (this.disabled = false)]
                    : (firstError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: firstError}" > Please enter First name. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Middle Name</label>
              <input
                type="text" class="form-control" name="middle_name" v-model="form.middle_name" placeholder="Middle Name" @keyup="
                  form.middle_name.length > 0
                    ? [(middleError = 'none'), (this.disabled = false)]
                    : (middleError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: middleError}" > Please enter Middle name. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Last Name</label>
              <input
                type="text" class="form-control" name="last_name" v-model="form.last_name" placeholder="Last Name" @keyup="
                  form.last_name.length > 0
                    ? [(lastError = 'none'), (this.disabled = false)]
                    : (lastError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: lastError}" > Please enter Last name. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Email</label>
              <input
                type="text" class="form-control" name="email" v-model="form.email" placeholder="Email" @keyup="
                  form.email.length > 0
                    ? [(emailError = 'none'), (this.disabled = false)]
                    : (emailError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: emailError}" > Please enter Email. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">mobile</label>
              <input
                :readonly="form.id ? true : false"
                type="number" class="form-control" name="mobile" v-model="form.mobile" id="mobile" placeholder="Mobile" @keyup="
                  form.mobile.toString().length > 0
                    ? [(mobileError = 'none'), (this.disabled = false)]
                    : (mobileError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: mobileError}" > Please enter Mobile. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Address</label>
              <textarea class="form-control" name="address" v-model="form.address" placeholder="Address" @keyup="
                  form.address.length > 0
                    ? [(addressError = 'none'), (this.disabled = false)]
                    : (addressError = 'block')
                "></textarea>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: addressError}" > Please enter Address. </div>
            </div>
            <div class="row mb-1">
              <div class="col-10">
                  <label class="form-label" for="basic-addon-name">Profile</label>
                  <input type="file" ref="file" class="form-control" name="profile" placeholder="Profile" @change="uploadImage" />
              </div>
              <div class="col-2">
                <div id="user-avatar-section">
                  <div id="d-flex justify-content-start">
                    <img v-if="url" :src="url" class="img-fluid rounded" height="104" width="104"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-1">
              <Select2 v-model="form.dep_id" :options="$store.state.departmentData" :settings="{ placeholder: 'Choose Department' }" @select="depEvent($event)"/>
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{display: depError}"
              >
                Please Choose Department.
              </div>
            </div>
            <div class="mb-1">
              <Select2 v-model="form.desi_id" :options="$store.state.designationData" :settings="{ placeholder: 'Choose Designation' }" @select="desiEvent($event)"/>
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{display: desiError}"
              >
                Please Choose Designation.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Wallet Balance</label>
              <input
                type="number" class="form-control" step="any" name="wallet_balance" v-model="form.wallet_balance" id="wallet_balance" placeholder="wallet_balance" @keyup="
                  form.wallet_balance.toString().length > 0
                    ? [(walletBalanceError = 'none'), (this.disabled = false)]
                    : (walletBalanceError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: walletBalanceError}" > Please enter Wallet Balance. </div>
            </div>
            <div class="border p-1">
              <div class="mb-1">
                <Select2 v-model="form.gate_id" :options="$store.state.entryExitIpPortData" @change="myChangeEvent($event)" :settings="{ placeholder: 'Choose Gate' }" @select="mySelectEvent($event)"/>
                <div
                  class="invalid-feedback fw-bold fst-italic"
                  :style="{display: gateIdError}"
                >
                  Please Choose Gate name.
                </div>
              </div>
              <div class="mb-1">
                <Select2 v-model="form.type_id" :options="type" @change="myChangeType($event)" :settings="{ placeholder: 'Choose Type' }" @select="myChangeType($event)"/>
              </div>
              <div class="mb-1" v-show="form.type_id == 1">
                <label class="form-label" for="basic-addon-name"
                  >Entry Ip</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="entry_ip"
                  v-model="form.entry_ip"
                  placeholder="Entry Ip"
                  @keyup="
                    form.entry_ip.length > 0
                      ? [(entryIpError = 'none'), (disabled = false)]
                      : (entryIpError = 'block')
                  "
                />
                <div
                  class="invalid-feedback fw-bold fst-italic"
                  :style="{display: entryIpError}"
                >
                  Please enter entry ip.
                </div>
              </div>
              <div class="mb-1" v-show="form.type_id == 1">
                <label class="form-label" for="basic-addon-name"
                  >Entry Port</label
                >
                <input
                  type="number"
                  class="form-control"
                  name="entry_port"
                  v-model="form.entry_port"
                  placeholder="Entry Port"
                  @keyup="
                    form.entry_port.toString().length > 0
                      ? [(entryPortError = 'none'), (disabled = false)]
                      : (entryPortError = 'block')
                  " 
                />
                <div
                  class="invalid-feedback fw-bold fst-italic"
                  :style="{display: entryPortError}"
                >
                  Please enter entry Port.
                </div>
              </div>
              <div class="mb-1" v-show="form.type_id == 2">
                <label class="form-label" for="basic-addon-name"
                  >Exit Ip</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="exit_ip"
                  v-model="form.exit_ip"
                  placeholder="Exit Ip"
                  @keyup="
                    form.exit_ip.length > 0
                      ? [(exitIpError = 'none'), (disabled = false)]
                      : (exitIpError = 'block')
                  "
                />
                <div
                  class="invalid-feedback fw-bold fst-italic"
                  :style="{display: exitIpError}"
                >
                  Please enter exit ip.
                </div>
              </div>
              <div v-show="form.type_id == 2">
                <label class="form-label" for="basic-addon-name"
                  >Exit Port</label
                >
                <input
                  type="number"
                  class="form-control"
                  name="exit_port"
                  v-model="form.exit_port"
                  placeholder="Exit Port"
                  @keyup="
                    form.exit_port.toString().length > 0
                      ? [(exitPortError = 'none'), (disabled = false)]
                      : (exitPortError = 'block')
                  "
                />
                <div
                  class="invalid-feedback fw-bold fst-italic"
                  :style="{display: exitPortError}"
                >
                  Please enter Exit Port.
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary me-1 data-submit"
              :disabled="disabled"
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              :disabled="disabled"
              data-bs-dismiss="modal"
              @click="[isShow='', isDisplay='none']"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Select2 from 'vue3-select2-component';
import Form from 'vform';

export default {
  name: "Employee",
  components: {
    Pagination: LaravelVuePagination,
    Select2
  },
  data() {
    return {
      form: new Form({
        id: null,
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        mobile: "",
        address: "",
        profile: "",
        dep_id: "",
        desi_id: "",
        wallet_balance: "",
        //////////////
        type_id: "",
        gate_id: "",
        entry_ip: "",
        entry_port: "",
        exit_ip: "",
        exit_port: "",
      }),
      url: null,
      response: "",
      disabled: false,
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      firstError: "none",
      middleError: "none",
      lastError: "none",
      emailError: "none",
      mobileError: "none",
      addressError: "none",
      depError: "none",
      desiError: "none",
      walletBalanceError: "none",
      //////////////
      type: [
        {
          id: 0,
          text: "Choose Type"
        },
        {
          id: 1,
          text: "Entry"
        },
        {
          id: 2,
          text: "Exit"
        },
      ],
      gateIdError: "none",
      entryIpError: "none",
      entryPortError: "none",
      exitIpError: "none",
      exitPortError: "none",
      isShow: "",
      isDisplay: "none",
      token: localStorage.getItem('auth_token')
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Profile",
      "Department",
      "Designation",
      "First Name",
      // "Middle Name",
      "Last Name",
      "Email",
      "Mobile",
      //  "Wallet Balance",
      // "Created",
      "Action",
    ];
    this.getResults();
    this.$store.dispatch('getDepartmentList');
    this.$store.dispatch('getDesignationList');
    this.$store.dispatch('getEntryExitIpPortList');
  },
  methods: {
    depEvent({ id }) {
      this.form.dep_id = id;
      this.disabled = false;
    },
    desiEvent({ id }) {
      this.form.desi_id = id;
      this.disabled = false;
    },
    mySelectEvent({ id }) {
      console.log('id: ', id);
      this.gate_id = id;
      this.disabled = false;
    },
    myChangeType({ id }) {
      this.form.type_id = id;
      this.disabled = false;
    },
    async add() {
      (this.form.id = null),
      (this.form.first_name = ""),
      (this.form.middle_name = ""),
      (this.form.last_name = ""),
      (this.form.email = ""),
      (this.form.mobile = ""),
      (this.form.address = ""),
      (this.form.profile = ""),
      (this.form.dep_id = ""),
      (this.form.desi_id = ""),
      (this.form.wallet_balance = ""),
      
      (this.form.type_id = ""),
      (this.form.gate_id = ""),
      (this.form.entry_ip = ""),
      (this.form.entry_port = ""),
      (this.form.exit_ip = ""),
      (this.form.exit_port = ""),

      (this.response = "");
      this.$refs.file.value = "";
      this.url = "";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async getResults(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_URL+"/employee_master?page=" + page, 
        {
          headers: {
            "Content-Type": "application/json",
            'Accept': "application/json",
            "Authorization": `Bearer ${this.token}`
          }
        })
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    uploadImage(e) {
        let img = e.target.files[0]
        this.form.profile = img;
        this.url = URL.createObjectURL(img);
    },
    async submitForm() {
      this.disabled = true;
      if (this.form.first_name.length === 0) {
        this.firstError = "block"
      } else if (this.form.middle_name.length === 0) {
        this.middleError = "block"
      } else if (this.form.last_name.length === 0) {
        this.lastError = "block"
      } else if (this.form.email.length === 0) {
        this.emailError = "block"
      } else if (this.form.mobile.toString().length === 0) {
        this.mobileError = "block"
      } else if (this.form.desi_id.length === 0) {
        this.desiError = "block";
      } else if (this.form.dep_id.length === 0) {
        this.depError = "block";
      } 
      //  else if (this.form.wallet_balance.length === 0) {
      //   this.walletBalanceError = "block";
      // } 
      //////////////
      // else if (this.form.gate_id.length === 0) {
      //   this.gateIdError = "block"
      // } else if (this.form.entry_ip.length === 0) {
      //   this.entryIpError = "block";
      // } else if (this.form.entry_port.toString().length === 0) {
      //   this.entryPortError = "block";
      // } else if (this.form.exit_ip.length === 0) {
      //   this.exitIpError = "block";
      // } else if (this.form.exit_port.toString().length === 0) {
      //   this.exitPortError = "block";
      // } 
      else {
        this.depError = "none";
        this.desiError = "none";
        ///////////
        // this.gateIdError = "none";
        // this.entryIpError = "none";
        // this.entryPortError = "none";
        // this.exitIpError = "none";
        // this.exitPortError = "none";
        
        var apiUrl = '';
        if (this.form.id) {
          apiUrl = process.env.VUE_APP_API_URL+"/employee_master/update/" + this.form.id;
        } else {
          apiUrl = process.env.VUE_APP_API_URL+"/employee_master";
        }

        await this.form.post(apiUrl,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          })
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message);
              this.isShow = ""
              this.isDisplay = "none"
              this.disabled = false;
              this.getResults();
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.disabled = false;
          });
      }
    },
    deleteDes(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL+"/employee_master/" + id,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then(response => {
              this.getResults();
              if (response.data.status) {
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async edit(id) {
      this.firstError= "none"
      this.middleError= "none"
      this.lastError= "none"
      this.emailError= "none"
      this.mobileError= "none"
      this.addressError= "none"
      this.depError= "none"
      this.desiError= "none"
      this.walletBalanceError= "none"
      /////////
      // this.gateIdError = "none";
      // this.entryIpError = "none";
      // this.entryPortError = "none";
      // this.exitIpError = "none";
      // this.exitPortError = "none";

      this.disabled= false

      const output = await axios.get(process.env.VUE_APP_API_URL+"/employee_master" +(id ? "/" + id : ""),
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              });
    
      this.form.id = output.data.data?.id;
      this.form.first_name = output.data.data?.first_name;
      this.form.middle_name = output.data.data?.middle_name;
      this.form.last_name = output.data.data?.last_name;
      this.form.email = output.data.data?.email;
      this.form.mobile = output.data.data?.mobile;
      this.form.address = output.data.data?.address;
      this.form.profile = output.data.data?.profile;
      this.form.dep_id = output.data.data?.dep_id;
      this.form.desi_id = output.data.data?.desi_id;
      this.form.wallet_balance = output.data.data?.wallet_balance;
      
      this.form.type_id = output.data.data?.type_id;

      this.form.gate_id = output.data.data?.gate_id;
      this.form.entry_ip = output.data.data?.entry_ip;
      this.form.entry_port = output.data.data?.entry_port;
      this.form.exit_ip = output.data.data?.exit_ip;
      this.form.exit_port = output.data.data?.exit_port;

      this.url = output.data.data?.profile_url

      this.modalName = "Edit";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async searchData() {
      await axios
        .post(process.env.VUE_APP_API_URL+"/employee_master/search",
          {
            search: this.search,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data.data;
            this.isShow = ""
            this.isDisplay = "none"
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
  },
};
</script>
